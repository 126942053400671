import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'

import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import LayoutGame from '../../components/LayoutGame'
import useConfiguration from '../../hooks/useConfiguration'
import GamePageTemplate from '../../templates/GamePage'
import { useIntl } from '../../intl/intl'
import router from '../../router'
import routes from '../../router/routes'
import * as appConfiguration from '../../configuration'


const Home = (props) => {
  const { pageContext, location } = props
  const { seoFallback } = useConfiguration()
  const t = useIntl()

  const homepageProps = useMemo(() => ({
    layoutProps: {
      videoBackgroundSources: [
        {
          src: t('background_video_path'),
          type: 'video/mp4',
        },
      ],
      seo: {
        lang: pageContext?.language ?? null,
        title: seoFallback?.title ?? '',
        description: seoFallback?.description?.description ?? '',
        image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
      },
    },
    startNavProps: {
      buttons: [
        {
          text: t('play_now'),
          variant: 'bigwhite',
          gatsbyLink: {
            route: router(pageContext?.isVip ? routes.vipGame.path : routes.game.path),
          },
        },
        {
          text: t('home_lookbook'),
          variant: 'bigtransparent',
          gatsbyLink: {
            route: router(pageContext?.isVip ? routes.vipLooks.path : routes.looks.path),
          },
        },
      ],
    },
  }), [seoFallback.title, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, location.href, t, pageContext])

  return (
    <LayoutGame {...homepageProps.layoutProps}>
      <GamePageTemplate startNavProps={homepageProps.startNavProps} />
    </LayoutGame>
  )
}

Home.propTypes = {
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

Home.defaultProps = {
  pageContext: null,
  location: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(Home)))
